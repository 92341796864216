@import '@vg-constellation/angular-18/styles/functions';

$custom-icons-medium: 'info', 'warning-circle', 'shield-check', 'green-check-mark', 'party-popper',
  'error-fill-red', 'warning-fill-red', 'recurring';
$custom-icons-small: 'info', 'warning-circle', 'green-check-mark', 'error-fill-red',
  'warning-fill-red', 'recurring';

@each $icon-name in $custom-icons-medium {
  .c11n-#{$icon-name}-medium {
    height: rem(24);
    width: rem(24);
  }
}

@each $icon-name in $custom-icons-small {
  .c11n-#{$icon-name}-small {
    height: rem(14);
    width: rem(14);
  }
}
