@import '@vg-constellation/angular-18/styles/breakpoints';
@import '@vg-constellation/angular-18/styles/typography';
@import '@vg-constellation/angular-18/styles/variables';

.twe-inline-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  margin-left: -$c11n-size-space-4x;

  li {
    @include c11n-font-rules('regular', 'sm');
    display: inline-block;
    margin-bottom: $c11n-size-space-2x;
    white-space: nowrap;

    &:before {
      content: '';
      border-left: $c11n-size-border-width-thin solid $c11n-color-ink-300;
      margin: 0 $c11n-size-space-2x;
    }
  }
}

.twe-unstyled-list {
  list-style: none;
  padding: 0;
}
