/* You can add global styles to this file, and also import other style files */

@import '@vg-constellation/angular-18/styles/c11n';
@import '@angular/cdk/overlay';
@import '@angular/cdk/text-field';
@import '@angular/cdk/a11y';
@import './styles.constants';
@import './styles.overrides.bootstrap';
@import './styles.mixins';
@import './styles.icons';

/* vg global header modifiers */
#noNav {
  display: none;
}

#vgn-globalHeader {
  border-bottom: solid 1px $c11n-color-ink-300;

  &.vgn-socialFooter {
    display: none;
  }

  &.vgn-superFooter {
    display: none;
  }
}

.content {
  text-align: center;
}

.border-top {
  border-top: solid 1px $c11n-color-ink-800;
}

.border-bottom {
  border-bottom: solid 1px $c11n-color-ink-800;
}

.mftable {
  background-color: $c11n-color-ink-100;
  font-size: 12px;
  line-height: 20px;
  border-collapse: collapse;
  width: 100%;

  tr {
    border-bottom: 1px solid $c11n-color-ink-400;
  }

  tr:last-of-type {
    border-bottom: none;
  }

  th,
  .fa-row-th {
    padding: 14px 0;
    vertical-align: top;
  }

  td,
  .fa-row-td {
    padding: 14px 0;
    vertical-align: top;
    font-weight: 700;
    color: $c11n-color-ink-800;
    text-align: right;
  }
}

.tinytext {
  font-size: 12px !important;
}

.plainTextBtn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/* For highlighting the Radio label text if selected */
.selectedRadio c11n-radio label span.c11n-radio__label-content {
  @include c11n-font-rules('bold', 'md');
}

c11n-select#selectBank c11n-label .c11n-label--small .c11n-label__content {
  @include c11n-font-rules('bold', 'md');
}

#selectBank .c11n-label {
  padding: 0.8rem 0;
}

.cursor-pointer {
  cursor: pointer;
}

@each $breakpoint in $responsive-breakpoints {
  .visually-hidden-#{$breakpoint} {
    @include visually-hidden-for-breakpoint($breakpoint);
  }
}
