@import './styles.constants';

@mixin custom-font-size($size) {
  font-size: #{$size}px;
}

@mixin visually-hidden-for-breakpoint($breakpoint) {
  @if $breakpoint == $below-xxxl {
    @include below-xxxl {
      @include visually-hidden;
    }
  } @else if $breakpoint == $below-xxl {
    @include below-xxl {
      @include visually-hidden;
    }
  } @else if $breakpoint == $below-xl {
    @include below-xl {
      @include visually-hidden;
    }
  } @else if $breakpoint == $below-lg {
    @include below-lg {
      @include visually-hidden;
    }
  } @else if $breakpoint == $below-md {
    @include below-md {
      @include visually-hidden;
    }
  } @else if $breakpoint == $below-sm {
    @include below-sm {
      @include visually-hidden;
    }
  }
}

@mixin mobile-only {
  @media only screen and (max-width: $sm - 1) {
    @content;
  }
}

@mixin tablet-small-only {
  @media only screen and (min-width: $sm) and (max-width: $md - 1) {
    @content;
  }
}

@mixin mobile-and-tablet-small-only {
  @media only screen and (max-width: $md - 1) {
    @content;
  }
}

@mixin tablet-large-only {
  @media only screen and (min-width: $md) and (max-width: $lg - 1) {
    @content;
  }
}

// tablets-only is combination of tablet-small-only and tablet-large-only
@mixin tablets-only {
  @media only screen and (min-width: $sm) and (max-width: $lg - 1) {
    @content;
  }
}

@mixin desktop-only {
  @media only screen and (min-width: $lg) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (min-width: 0) {
    @content;
  }
}

@mixin tablet-small {
  @media only screen and (min-width: $sm) {
    @content;
  }
}

@mixin tablet-large {
  @media only screen and (min-width: $md) {
    @content;
  }
}

@mixin square($s, $important: null) {
  width: $s $important;
  height: $s $important;
}

@mixin visually-hidden {
  position: absolute !important;
  @include square(1px, !important);

  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

/// Converts px to rem:
/// the provided value is divided by 16.
/// (by default, 1rem equals to 16px).
///
/// @param {number} $px-value
@function px-to-rem($px-value) {
  @return calc($px-value / 16) + rem;
}

@mixin xs-only {
  @media only screen and (max-width: ($sm - 1)) {
    @content;
  }
}

@mixin sm-only {
  @media only screen and (min-width: $sm) and (max-width: ($md - 1)) {
    @content;
  }
}

@mixin sm-and-above {
  @media only screen and (min-width: $sm) and (max-width: 99999px) {
    @content;
  }
}

@mixin md-only {
  @media only screen and (min-width: $md) and (max-width: ($lg - 1)) {
    @content;
  }
}

@mixin md-and-above {
  @media only screen and (min-width: $md) and (max-width: 99999px) {
    @content;
  }
}

@mixin below-md {
  @media only screen and (max-width: ($md - 1)) {
    @content;
  }
}

@mixin lg-only {
  @media only screen and (min-width: $lg) and (max-width: ($xl - 1)) {
    @content;
  }
}

@mixin lg-and-above {
  @media only screen and (min-width: $lg) and (max-width: 99999px) {
    @content;
  }
}

@mixin below-lg {
  @media only screen and (max-width: ($lg - 1)) {
    @content;
  }
}

@mixin xl-only {
  @media only screen and (min-width: $xl) and (max-width: ($xxl - 1)) {
    @content;
  }
}

@mixin xl-and-above {
  @media only screen and (min-width: $xl) and (max-width: 99999px) {
    @content;
  }
}

@mixin below-xl {
  @media only screen and (max-width: ($xl - 1)) {
    @content;
  }
}

@mixin xxl-only {
  @media only screen and (min-width: $xxl) and (max-width: 99999px) {
    @content;
  }
}

@mixin below-xxl {
  @media only screen and (max-width: ($xxl - 1)) {
    @content;
  }
}

@mixin below-xxxl {
  @media only screen and (max-width: 99999px) {
    @content;
  }
}

@mixin below-sm {
  @include xs-only {
    @content;
  }
}
